export default {
  name: "OrderFilterLayout",

  data() {
    return {
      //VARIABLES
      texts: "",
      aorderStatus: [],
      aclaimsStatus: [],
      orderStatus: [],
      claimsStatus: [],
      rangeStatus: [],
      paymentStatusPaid: null,
      paymentStatusToPaid: null,
      paymentStatus: null,
      userStatusActive: null,
      userStatusInactive: null,
      userStatus: null,
      alphabeticallyStatusAZ: null,
      alphabeticallyStatusZA: null,
      alphabeticallyStatus: null,
      priceStatusFromLowestToHighest: null,
      priceStatusFromHighestToLowest: null,
      priceStatus: null,
      tab: 0,
    };
  },
  beforeMount() {
    if (this.$route.name === "Orders") {
      this.tab = 0; // filtro de dos estados de pedidos y estado de pago
    } else if (this.$route.name === "Claims") {
      this.tab = 1; // filtro de estado de disputas y estado de pago
    } else if (
      this.$route.name === "Customers" ||
      this.$route.name === "Vendors" ||
      this.$route.name === "Subadmins"
    ) {
      this.tab = 2; // filtro de estado de usuario
    } else if (
      this.$route.name === "Articles" ||
      this.$route.name === "VendorsDetails"
    ) {
      this.tab = 3; // filtro de calificacion, alfabeticamente y precio
    }
    this.texts = FILE.filterTexts[this.selectLanguage];
    this.getStatusOrder();
    this.getStatusClaims();
  },
  methods: {
    getStatusOrder: function() {
      DB.get(`${URI}/api/v1/${this.selectLanguage}/status/purchase-orders`, {
        headers: {
          Authorization: "Bearer " + this.$store.state.sToken,
        },
        params: {},
      })
        .then((response) => {
          this.aorderStatus = response.data.results;
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    getStatusClaims: function() {
      DB.get(`${URI}/api/v1/${this.selectLanguage}/status/claim-tickets`, {
        headers: {
          Authorization: "Bearer " + this.$store.state.sToken,
        },
        params: {},
      })
        .then((response) => {
          this.aclaimsStatus = response.data.results;
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    changeStatusPaid: function(e) {
      this.paymentStatusToPaid = null;

      if (this.paymentStatusPaid == true) {
        this.paymentStatus = true;
      } else {
        this.paymentStatus = null;
      }
    },
    changeStatusToPaid: function() {
      this.paymentStatusPaid = null;

      if (this.paymentStatusToPaid == true) {
        this.paymentStatus = false;
      } else {
        this.paymentStatus = null;
      }
    },
    changeStatusActive: function(e) {
      this.userStatusInactive = null;

      if (this.userStatusActive == true) {
        this.userStatus = true;
      } else {
        this.userStatus = null;
      }
    },
    changeStatusInactive: function() {
      this.userStatusActive = null;

      if (this.userStatusInactive == true) {
        this.userStatus = false;
      } else {
        this.userStatus = null;
      }
    },
    changeStatusAZ: function(e) {
      this.alphabeticallyStatusZA = null;

      if (this.alphabeticallyStatusAZ == true) {
        this.alphabeticallyStatus = true;
      } else {
        this.alphabeticallyStatus = null;
      }
    },
    changeStatusZA: function() {
      this.alphabeticallyStatusAZ = null;

      if (this.alphabeticallyStatusZA == true) {
        this.alphabeticallyStatus = false;
      } else {
        this.alphabeticallyStatus = null;
      }
    },
    changeStatusFromLowestToHighest: function(e) {
      this.priceStatusFromHighestToLowest = null;

      if (this.priceStatusFromLowestToHighest == true) {
        this.priceStatus = true;
      } else {
        this.priceStatus = null;
      }
    },
    changeStatusFromHighestToLowest: function() {
      this.priceStatusFromLowestToHighest = null;

      if (this.priceStatusFromHighestToLowest == true) {
        this.priceStatus = false;
      } else {
        this.priceStatus = null;
      }
    },
  },
  computed: {
    selectLanguage() {
      return this.$store.state.language;
    },
    sFilter() {
      return this.$store.state.sFilter;
    },
  },
  watch: {
    selectLanguage: function() {
      if (this.selectLanguage) {
        this.texts = FILE.filterTexts[this.selectLanguage];
        if (this.$route.name === "Orders") {
          this.getStatusOrder();
        } else if (this.$route.name === "Claims") {
          this.getStatusClaims();
        }
      }
    },
    orderStatus: function() {
      this.$store.commit("setOrderStatus", this.orderStatus);
    },
    claimsStatus: function() {
      this.$store.commit("setClaimStatus", this.claimsStatus);
    },
    rangeStatus: function() {
      this.$store.commit("setRangeStatus", this.rangeStatus);
    },
    alphabeticallyStatus: function() {
      this.$store.commit("setAlphabeticallyStatus", this.alphabeticallyStatus);
    },
    priceStatus: function() {
      this.$store.commit("setPriceStatus", this.priceStatus);
    },
    paymentStatus: function() {
      this.$store.commit("setPaymentStatus", this.paymentStatus);
    },
    userStatus: function() {
      this.$store.commit("setUserState", this.userStatus);
    },
  },
};
